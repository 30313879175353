import type { Shipment } from 'src/types';

import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Card, useTheme, CardHeader, CardContent } from '@mui/material';

type Props = {
  searchQuery: string;
  shipments: Shipment[];
  loading: boolean;
  handleClick: (id: number) => void;
};

export default function ResultList({ searchQuery, shipments, loading, handleClick }: Props) {
  const theme = useTheme();
  const regExp = new RegExp(searchQuery, 'i');

  const highlight = (value: any) => {
    const text = value?.toString?.() || '';
    const start = text?.match(regExp)?.index;
    if (searchQuery && text && start != null) {
      const stop = start + searchQuery.length;
      const before = text.substring(0, start);
      const match = text.substring(start, stop);
      const after = text.substring(stop);
      return (
        <span>
          {before}
          <b
            style={{
              color: theme.palette.text.primary,
              background: theme.palette.primary.light,
              borderRadius: 4,
              padding: 2,
            }}
          >
            {match}
          </b>
          {after}
        </span>
      );
    }
    return text || '-';
  };

  return (
    <Card
      sx={{
        width: '100%',
        margin: 0,
        padding: 0,
        borderRadius: 2,
      }}
    >
      <CardHeader title="Search Results" sx={{ py: 2 }} />

      <CardContent
        sx={{
          padding: 0,
          margin: 0,
        }}
      >
        <DataGridPremium
          sx={{
            '.MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
          }}
          loading={loading}
          rows={shipments}
          columns={[
            {
              field: 'orderNumber',
              headerName: 'Order',
              flex: 1,
              renderCell: ({ value }) => highlight(value),
            },
            {
              field: 'masterBolNumber',
              headerName: 'Master BOL',
              flex: 2,
              renderCell: ({ value }) => highlight(value),
            },
            {
              field: 'houseBolNumber',
              headerName: 'House BOL',
              flex: 2,
              renderCell: ({ value }) => highlight(value),
            },
            {
              field: 'primaryContainerNumber',
              headerName: 'Primary Container #',
              flex: 2,
              renderCell: ({ value }) => highlight(value),
            },
            {
              field: 'secondaryContainerNumber',
              headerName: 'Secondary Container #',
              flex: 2,
              renderCell: ({ value }) => highlight(value),
            },
            {
              field: 'primaryChassisNumber',
              headerName: 'Primary Chassis #',
              flex: 2,
              renderCell: ({ value }) => highlight(value),
            },
            {
              field: 'secondaryChassisNumber',
              headerName: 'Secondary Chassis #',
              flex: 2,
              renderCell: ({ value }) => highlight(value),
            },
          ]}
          onRowClick={({ row }) => handleClick(row.id)}
        />
      </CardContent>
    </Card>
  );
}
