import type { Location } from 'src/types';

import useSWR from 'swr';
import { useMemo } from 'react';

import axios, { fetcher, endpoints } from 'src/lib/axios';

const URL = endpoints.locations;

export function useListLocations(filter?: any, sort?: any, pagination?: any, relations?: any) {
  const request: any = [
    URL,
    {
      params: {
        filter,
        sort,
        pagination,
        relations,
      },
    },
  ];

  const { data, mutate, isLoading, error, isValidating } = useSWR(request, fetcher);

  const memoizedValue = useMemo(
    () => ({
      locations: (data || []) as Location[],
      mutate,
      isLoading,
      error,
      isValidating,
      isEmpty: !isLoading && !data?.length,
    }),
    [data, mutate, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function useGetLocation(id: any, relations?: any) {
  const request = id && [`${URL}/${id}`, { params: { relations } }];
  const { data, isLoading, error, isValidating } = useSWR(request, fetcher);

  const memoizedValue = useMemo(
    () => ({
      location: data as Location,
      locationLoading: isLoading,
      locationError: error,
      locationValidating: isValidating,
      locationEmpty: !isLoading && !data?.length,
    }),
    [data, error, isLoading, isValidating]
  );

  return memoizedValue;
}

export function createLocation(location: Location) {
  return axios.post(URL, location);
}

export function updateLocation(location: Location) {
  return axios.put(`${URL}/${location.id}`, location);
}

export async function saveLocation(location: Location) {
  return location.id ? updateLocation(location) : createLocation(location);
}

export function deleteLocation(id: number) {
  return axios.delete(`${URL}/${id}`);
}

export function importLocations(fileData: any) {
  const formData = new FormData();
  formData.append('file', fileData);

  return axios.post(`${URL}/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
