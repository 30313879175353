import type { NavSectionProps } from 'src/components/nav-section';

import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { CONFIG } from 'src/global-config';
import { useListReports } from 'src/actions';

import { Iconify } from 'src/components/iconify';
import { SvgColor } from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`${CONFIG.assetsDir}/assets/icons/navbar/${name}.svg`} />
);

const iconify = (name: string) => <Iconify icon={name} />;

const ICONS = {
  dashboard: icon('ic-dashboard'),

  // overview
  openShipments: iconify('ph:shipping-container'),
  billedShipments: iconify('ph:shipping-container-fill'),
  dispatchedTrips: iconify('mdi:truck'),
  undispatchedTrips: iconify('mdi:truck-outline'),
  invoice: iconify('hugeicons:money-receive-square'),
  payment: iconify('hugeicons:money-send-square'),
  reports: iconify('mingcute:table-2-fill'),

  // management
  user: iconify('mdi:user'),
  group: iconify('mdi:account-group'),
  customer: iconify('mdi:company'),
  location: iconify('mdi:location'),
  truck: iconify('mdi:truck'),
  terminal: iconify('zondicons:network'),
  steamship: iconify('mdi:boat'),
  template: iconify('mdi:message-alert'),
  report: iconify('mdi:file-report'),
  
  import: iconify('mdi:import'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { reports } = useListReports();

  const navData: NavSectionProps['data'] = useMemo(
    () => [
      /**
       * Overview
       */
      {
        subheader: 'Overview',
        items: [
          { title: 'App', path: paths.dashboard.root, icon: ICONS.dashboard },
          {
            title: 'Open Shipments',
            path: paths.dashboard.shipments.list,
            icon: ICONS.openShipments,
          },
          {
            title: 'Billed Shipments',
            path: paths.dashboard.billedShipments.list,
            icon: ICONS.billedShipments,
          },
          {
            title: 'Dispatched Trips',
            path: paths.dashboard.dispatchedTrips.list,
            icon: ICONS.dispatchedTrips,
          },
          {
            title: 'Undispatched Trips',
            path: paths.dashboard.undispatchedTrips.list,
            icon: ICONS.undispatchedTrips,
          },
          { title: 'Invoices', path: paths.dashboard.invoices.list, icon: ICONS.invoice },
          { title: 'Payments', path: paths.dashboard.driverPayments.list, icon: ICONS.payment },
          {
            title: 'Reports',
            path: paths.dashboard.reportsView.root,
            icon: ICONS.reports,
            children: reports.map((r) => ({
              title: r.name,
              path: paths.dashboard.reportsView.view(r.id),
            })),
          },
        ],
      },

      /**
       * Management
       */
      {
        subheader: 'Management',
        items: [
          { title: 'Users', path: paths.dashboard.users.list, icon: ICONS.user },
          { title: 'Groups', path: paths.dashboard.groups.list, icon: ICONS.group },
          { title: 'Customers', path: paths.dashboard.customers.list, icon: ICONS.customer },
          { title: 'Locations', path: paths.dashboard.locations.list, icon: ICONS.location },
          { title: 'Terminals', path: paths.dashboard.terminals.list, icon: ICONS.terminal },
          { title: 'Trucks', path: paths.dashboard.trucks.list, icon: ICONS.truck },
          { title: 'Steamships', path: paths.dashboard.steamships.list, icon: ICONS.steamship },
          { title: 'Templates', path: paths.dashboard.templates.list, icon: ICONS.template },
          { title: 'Reports', path: paths.dashboard.reports.list, icon: ICONS.report },
        ],
      },
      // Tools
      {
        subheader: 'Tools',
        items: [
          {
            title: 'Import Data',
            path: paths.dashboard.imports.root,
            icon: ICONS.import,
          },
        ],
      },
    ],
    [reports]
  );
  return navData;
}
