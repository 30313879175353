import type { Shipment } from 'src/types';

import moment from 'moment';
import { uniq } from 'es-toolkit';
import { useMemo, useState } from 'react';

import {
  Card,
  Table,
  Switch,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  CardHeader,
  TableContainer,
  FormControlLabel,
} from '@mui/material';

import { formatDate } from 'src/utils/format-time';

import {
  useListCustomers,
  useListLocations,
  useListShipments,
  useListShipmentDeliveries,
  useListRecentlyViewedShipments,
} from 'src/actions';

type Props = {
  handleClick: (id: number) => void;
};

export default function RecentList({ handleClick }: Props) {
  const [showDetail, setShowDetail] = useState(false);
  const { recentlyViewedShipments: recent } = useListRecentlyViewedShipments();
  const filter = { id: recent.map((r) => r.shipmentId) };
  const sort = undefined;
  const pagination = undefined;
  const relations = { shipmentType: true, shipmentStatus: true };
  const { shipments } = useListShipments(filter, sort, pagination, relations);
  const customerIds = useMemo(
    () => uniq(shipments.flatMap((s) => [s.primaryBillToId, s.containerPickupId])),
    [shipments]
  );
  const { customers } = useListCustomers({ id: customerIds });

  const shipmentIds = useMemo(() => uniq(shipments.map((s) => s.id)), [shipments]);
  const { shipmentDeliveries } = useListShipmentDeliveries({ shipmentId: shipmentIds });

  const locationIds = useMemo(
    () => uniq(shipmentDeliveries.map((d) => d.locationId)),
    [shipmentDeliveries]
  );
  const { locations } = useListLocations({ id: locationIds });

  const enrichedShipments = useMemo(
    () =>
      shipments.map((shipment: Shipment) => {
        const recentEntry = recent.find((r) => r.shipmentId === shipment.id);
        return {
          ...shipment,
          viewedAt: recentEntry?.viewedAt || null,
        };
      }),
    [recent, shipments]
  );

  const sorted = useMemo(
    () =>
      enrichedShipments.sort(
        (a, b) =>
          recent.findIndex((r) => r.shipmentId === a.id) -
          recent.findIndex((r) => r.shipmentId === b.id)
      ),
    [enrichedShipments, recent]
  );

  return (
    <Card
      sx={{
        width: '100%',
        margin: 0,
        padding: 2,
        borderRadius: 2,
      }}
    >
      <CardHeader
        title="Recent Shipments"
        sx={{ mb: 1, p: 0 }}
        action={
          <FormControlLabel
            control={
              <Switch
                checked={showDetail}
                onChange={(e) => setShowDetail(e.target.checked)}
                color="primary"
              />
            }
            label="Show Details"
          />
        }
      />
      <TableContainer
        sx={{
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 2,
          margin: 0,
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Order#</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Pickup</TableCell>
              <TableCell>DeliverTo</TableCell>
              <TableCell>Container#</TableCell>
              <TableCell>MBOL#</TableCell>
              {showDetail && <TableCell>PU#</TableCell>}
              {showDetail && <TableCell>BK#</TableCell>}
              {showDetail && <TableCell>Lfd</TableCell>}
              {showDetail && <TableCell>Appt</TableCell>}
              {showDetail && <TableCell>Notified</TableCell>}
              <TableCell>Viewed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sorted.map((s) => (
              <TableRow
                key={s.id}
                hover
                onClick={() => handleClick(s.id)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': {
                    backgroundColor: 'action.hover',
                  },
                }}
              >
                <TableCell>{s.orderNumber}</TableCell>
                <TableCell>{s.shipmentType?.name}</TableCell>
                <TableCell>{s.shipmentStatus?.name}</TableCell>
                <TableCell>{customers?.find((c) => c.id === s.primaryBillToId)?.name}</TableCell>
                <TableCell>{customers?.find((c) => c.id === s.containerPickupId)?.name}</TableCell>
                <TableCell>
                  {
                    locations?.find(
                      (l) =>
                        l.id === shipmentDeliveries?.find((d) => d.shipmentId === s.id)?.locationId
                    )?.name
                  }
                </TableCell>
                <TableCell>{s.primaryContainerNumber}</TableCell>
                <TableCell>{s.masterBolNumber || '-'}</TableCell>
                {showDetail && <TableCell>{s.containerPickupNumber || '-'}</TableCell>}
                {showDetail && <TableCell>{s.containerBookingNumber || '-'}</TableCell>}
                {showDetail && (
                  <TableCell>
                    {s.extendedStorageLfd
                      ? formatDate(s.extendedStorageLfd)
                      : formatDate(s.storageLfd)}
                  </TableCell>
                )}
                {showDetail && (
                  <TableCell>
                    {formatDate(
                      shipmentDeliveries?.find((d) => d.shipmentId === s.id)?.appointmentFrom
                    )}
                  </TableCell>
                )}
                {showDetail && <TableCell>{formatDate(s.notifiedAt)}</TableCell>}
                <TableCell>{s.viewedAt ? moment(new Date(s.viewedAt)).fromNow() : '-'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
